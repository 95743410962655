
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































































































.domain-list-item {
  .app-button,
  .el-button {
    &:hover {
      transform: none;
    }
  }

  .el-button-group {
    @apply tw-w-9 xs:tw-w-20 sm:tw-w-44 tw-divide-y tw-divide-x-0 xs:tw-divide-y-0 xs:tw-divide-x tw-divide-white tw-divide-opacity-50;
  }

  .el-button-group > .el-button:first-child {
    @apply tw-rounded-sm tw-rounded-b-none xs:tw-rounded-sm xs:tw-rounded-r-none tw-border-r-0 xs:tw-border-r;
  }

  .el-button-group > .el-button:last-child {
    @apply tw-rounded-sm tw-rounded-t-none xs:tw-rounded-sm xs:tw-rounded-l-none;
  }
}
