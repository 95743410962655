
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

























































































































































































.organization-domains {
  .el-button {
    margin: 0;
    padding: 0;
    height: 36px;
    width: 36px;
    font-size: $--clb-font-size-xs;

    span {
      display: none;
    }

    i {
      font-size: $--clb-font-size-sm;
    }

    &:active,
    &:hover,
    &:focus {
      transform: translateY(0) !important;
    }
  }
}

@media (min-width: $--sm) {
  .organization-domains {
    .el-button {
      width: 88px;
      height: 36px;

      span {
        display: inline;
      }
    }
  }
}
